import React, { useState } from "react"
import CallbackForm from "../CallbackForm/CallbackForm"

import parse from "html-react-parser"

function InternalHero(props) {
  const hero = props?.internalHero
  const [open, setOpen] = useState(false)

  return (
    <div
      className="internal-hero"
      style={{
        backgroundPosition: "left top",
        backgroundImage: `url(${hero?.backgroundImage?.sourceUrl})`,
      }}
    >
      <div className="container">
        {hero?.callbackButtonText && (
          <div
            className="callback-button"
            onClick={() => {
              setOpen(!open)
            }}
          >
            <p>{hero?.callbackButtonText}</p>
          </div>
        )}

        {hero?.title && (
          <div className="title-wrapper">{parse(hero?.title)}</div>
        )}
        <a className="call-button" href={`tel:${hero?.button?.phoneNumber}`}>
          {hero?.button?.text}
        </a>
      </div>
      <CallbackForm open={open} setOpen={setOpen} />
    </div>
  )
}

export default InternalHero
